import RenderSections from "~/components/renderSections";
import type { AdditionalSectionData } from "~/components/renderSections";
import type { PageSingleQuery } from "~/types/queries";
interface Props {
	data: PageSingleQuery;
	additionalData?: AdditionalSectionData;
}

export function PageSingle({ data, additionalData }: Props) {
	return (
		<RenderSections
			sections={data?.content}
			additionalData={additionalData}
		/>
	);
}
